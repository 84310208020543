import { container } from "assets/jss/material-kit-react.js";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

const componentsStyle = {
  container,
  brand: {
    color: "#000",
    textAlign: "center",
  },
  pageTitle: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
  },
  title: {
    fontSize: "2.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    fontWeight: "300",
    display: "inline-block",
    position: "relative",
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#000",
    textAlign: "center !important",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: "auto !important",
  },
  marginLT: {
    marginLeft: "auto",
    marginTop: "5%",
  },
  ...imagesStyles,
};

export default componentsStyle;
