import React from "react";
// nodejs library that concatenates classes
import { Link } from "react-router-dom";
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import SectionPills from "./SectionPills";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function ProjectsPage() {
  const classes = useStyles();
  return (
    <div>
      <Header
        color="transparent"
        brand={
          <Link style={{ color: "#fff" }} to="/profile-page">
            Angel&apos;s Portfolio
          </Link>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "dark",
        }}
      />
      <Parallax small image={require("assets/img/bg.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Portfolio</h1>
                <h5 className={classes.subtitle}>
                  This is a collection of programs I have worked on.
                </h5>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionPills />
      </div>
      <Footer />
    </div>
  );
}
