import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Timer } from "@material-ui/icons";
import { ViewCarousel } from "@material-ui/icons/";
import { Restaurant } from "@material-ui/icons";
import { Theaters } from "@material-ui/icons";
import { Looks4 } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import PomodoroCarousel from "./ProjectCarousels/PomodoroCarousel";
import FlashCardCarousel from "./ProjectCarousels/FlashCardCarousel";
import MoviesCarousel from "./ProjectCarousels/MoviesCarousel";
import RestaurantCarousel from "./ProjectCarousels/RestaurantCarousel";
import ConnectFourCarousel from "./ProjectCarousels/ConnectFourCarousel";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div style={{ textAlign: "center" }} id="navigation-pills">
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                color="primary"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 3 },
                  contentGrid: { xs: 12, sm: 8, md: 9 },
                }}
                tabs={[
                  {
                    tabButton: "Restaurant Reservation",
                    tabIcon: Restaurant,
                    tabContent: (
                      <span>
                        <div className={classes.brand}>
                          <h1 className={classes.title}>
                            Restaurant Reservation System
                          </h1>
                        </div>
                        <RestaurantCarousel />
                        <div className={classes.buttonsection}>
                          <div className={classes.container}>
                            <Button
                              color="primary"
                              href="https://restaurant-resang-client.herokuapp.com/"
                              target="_blank"
                            >
                              Launch
                            </Button>
                          </div>
                        </div>
                        <p>
                          Full stack web reservation system. This was the
                          culmination of all the concepts and skills I learned
                          at Thinkful. The project is deployed on Heroku as two
                          seperate apps with the client making requests to the
                          server.
                        </p>
                        <br />
                        <p>
                          The frontend framework is react. Backend is routed and
                          handled with express.js. Knex package is used to make
                          PostgresQL queries. The database is hosted on
                          ElephantsQL
                        </p>
                        <br />
                        <p>
                          This project really pushed my understanding the
                          routing and handling of requests and data by an
                          application. My greatest growth during this project
                          was how backend frameworks can help simplify server
                          requests and how to write clean and effective database
                          request queries. I learned how to develop a full stack
                          workflow in order to keep frontend components and data
                          requests and edits consitant throughout the whole
                          application.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "We Love Movies App",
                    tabIcon: Theaters,
                    tabContent: (
                      <span>
                        <div className={classes.brand}>
                          <h1 className={classes.title}>We Love Movies App</h1>
                        </div>
                        <MoviesCarousel />
                        <div className={classes.buttonsection}>
                          <div className={classes.container}>
                            <Button
                              color="primary"
                              href="https://github.com/AngHernan/WeLoveMoviesBackendProject"
                              target="_blank"
                            >
                              Launch
                            </Button>
                          </div>
                        </div>
                        <p>
                          This project was purely a backend project that would
                          connect to the frontend component that is being shown.
                          The frontend was not written but all the backend
                          routes, middleware, and database requests are written
                          by me.
                        </p>
                        <br />
                        <p>
                          Completing this solidified my understanding of the
                          backend and how to seperate my backend routes and
                          middleware into seperate modules. This seperation
                          allowed me to understand how to write clean organized
                          code that is highly scallable.
                        </p>
                        <p>
                          The technology use for this program are express.js as
                          my backend framework, knex package to handle database
                          queries, ElephantsQL is where my database is hosted,
                          and finally node.js.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Flash Card Study App",
                    tabIcon: ViewCarousel,
                    tabContent: (
                      <span>
                        <div className={classes.brand}>
                          <h1 className={classes.title}>
                            Flash Card Study App
                          </h1>
                        </div>
                        <FlashCardCarousel />
                        <div className={classes.buttonsection}>
                          <div className={classes.container}>
                            <Button
                              color="primary"
                              href="https://angels-flashcards.herokuapp.com/"
                              target="_blank"
                            >
                              Launch
                            </Button>
                          </div>
                        </div>
                        <p>
                          This application primarily dealt with routing React
                          components and passing states between components. This
                          program started to give me a taste of how api requests
                          can be maid from the front end.
                        </p>
                        <br />
                        <p>
                          Currenlty deployed on Heroku and the backend is
                          anotherjson file database also running on heroku.
                          React is the frontend frame work used. Bootstrap is
                          used for the buttons and containers.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Pomodoro Timer",
                    tabIcon: Timer,
                    tabContent: (
                      <span>
                        <div className={classes.brand}>
                          <h1 className={classes.title}>Pomodoro Timer</h1>
                        </div>
                        <PomodoroCarousel />
                        <div className={classes.buttonsection}>
                          <div className={classes.container}>
                            <Button
                              color="primary"
                              href="https://pomodoro-angel.herokuapp.com/"
                              target="_blank"
                            >
                              Launch
                            </Button>
                          </div>
                        </div>
                        <p>
                          Simple Pomodoro style timer. THe project is meant to
                          introduce React and React compononts. I learned a lot
                          about conditional rendering, manipulating, and sharing
                          state between components.
                        </p>
                        <br />
                        <p>
                          This app dpends on the client to run the full
                          application which can bring up isssues about speed and
                          optimized machines to run heavier apps and the
                          proccessing of heavy data.
                        </p>
                        <br />
                      </span>
                    ),
                  },
                  {
                    tabButton: "Connect4.py",
                    tabIcon: Looks4,
                    tabContent: (
                      <span>
                        <div className={classes.brand}>
                          <h1 className={classes.title}> Connect4.py </h1>
                        </div>
                        <ConnectFourCarousel />
                        <div className={classes.buttonsection}>
                          <div className={classes.container}>
                            <Button
                              color="primary"
                              href="https://github.com/AngHernan/connectfourpy"
                              target="_blank"
                            >
                              Launch
                            </Button>
                          </div>
                        </div>
                        <p>
                          This is a connect four game that will show the game
                          board and player peices in text. This was implemented
                          using python and is meant to run on the desktop
                          console.
                        </p>
                        <br />
                        <p>
                          This project introduced me to object oriented
                          programing but it was not used extensively. This
                          project helped me understand how to format text
                          properly. The next most important concept I learned
                          was that of two dimensional lists. I also learned to
                          implement exceptions to catch game errors.
                        </p>
                        <br />
                        <p>
                          You can just download the repository to then just run
                          using Python 3.10 IDLE.
                        </p>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
