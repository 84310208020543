import React from "react";
// nodejs library that concatenates classes
import { Link } from "react-router-dom";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Palette from "@material-ui/icons/Palette";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import gradPhoto from "assets/img/faces/gradPhoto.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div>
      <Header
        color="transparent"
        brand={
          <Link style={{ color: "#fff" }} to="/profile-page">
            Angel&apos;s Portfolio
          </Link>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/sign.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Angel Hernandez.</h1>
                <h3 className={classes.subtitle}>Welcome to my Portfolio.</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={gradPhoto} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.titleDark}>Angel</h3>
                    <h6>Web/Software Developer</h6>
                    <Button
                      justIcon
                      link
                      href="https://twitter.com/AtomicHypernova"
                      target="_blank"
                      className={classes.margin5}
                    >
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      href="https://www.facebook.com/angel.hernandez.79069323"
                      target="_blank"
                      className={classes.margin5}
                    >
                      <i className={"fab fa-facebook"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      href="https://www.linkedin.com/in/anghernan/"
                      target="_blank"
                      className={classes.margin5}
                    >
                      <i className={"fab fa-linkedin"} />
                    </Button>
                    <Button
                      justIcon
                      link
                      href="https://github.com/AngHernan?tab=repositories"
                      target="_blank"
                      className={classes.margin5}
                    >
                      <i className={"fab fa-github"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                I&apos;m driven to learn as much as I possibly can about
                developing software and the web. The things we can do with
                techonolgy are limitless and are bound by our creativity. Coding
                was once too hard for me and I did not want to ever touch it.
                Surely but slowly I found my way back to it simply because of
                who I am. I enjoy solving problems and I love technology.
                Software development combined both of those passions for me and
                has allowed me to create things that I didn&apos;t think I could
                .
              </p>
              <p>
                During and after college I couldn&apos;t aswer the question of
                what I wanted my career to be. Coding had always been a part of
                my college career by taking intro python courses and using R for
                data analytics in my machine learning course. I didn&apos;t
                really feel confident in my coding skills. So for some time I
                believed I would work towards being an officer but that
                didn&apos;t feel right. A year after graduating and at the
                height of the pandemic a freind offered to tutor me in python
                and I really enjoyed creating projects. After that I set out on
                my journey to fund and ultimately complete a coding bootcamp to
                finally set myself on the path to find a career web and software
                development.
              </p>
              <p>
                I hope to continue my journey with coding far into the future
                for myself and my career. I want to contribute to open source
                projects, as well as large scale projects, and ultimately work
                on my own projects both small and large.
              </p>
            </div>
          </div>
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}
