import React from "react";
// nodejs library that concatenates classes
import { Link } from "react-router-dom";
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax";
import uci from "assets/img/educationLogos/uci.png";
import thinkful from "assets/img/educationLogos/thinkful.png";

import styles from "assets/jss/material-kit-react/views/education.js";

const useStyles = makeStyles(styles);

export default function EductionExp() {
  const classes = useStyles();
  return (
    <div>
      <Header
        color="transparent"
        brand={
          <Link style={{ color: "#fff" }} to="/profile-page">
            Angel&apos;s Portfolio
          </Link>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "dark",
        }}
      />
      <Parallax small image={require("assets/img/sign.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.pageTitle}>Education</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <div className={classes.space50} />
            <div id="education">
              <br />
              <GridContainer>
                <GridItem xs={2} sm={2} className={classes.marginLT}>
                  <img
                    small
                    src={thinkful}
                    alt="..."
                    className={classes.imgRounded + " " + classes.imgFluid}
                  />
                </GridItem>
                <GridItem xs={10} sm={9}>
                  <div className={classes.brand}>
                    <h5 className={classes.title}>Thinkful</h5>
                  </div>
                  <div className={classes.brand}>
                    <h3 className={classes.subtitle}> November 2021 </h3>
                  </div>
                  <br />
                  <div className={classes.description}>
                    <p>
                      Full-stack web development bootcamp centered around
                      understand how the web works and the languages,
                      environments, frameworks for both clients and servers, and
                      libraries are used to create dynamic applications that
                      communicate with real time databases filled with vital
                      information. Backend Applications are built using
                      Javascript for both the frontend and backend. Backend
                      servers are built on the node.js environment, using the
                      express.js framework, and ultimately using the knex
                      library to make PostgresQL database querries. Frontend
                      applications are built using the local browser as our
                      environment and REACT.js as our framework. These
                      technologies together create the core of our programs and
                      are scallable to larger audiences and needs. Within
                      applications we learn to use data structures to store our
                      data as well as solve problems using sets, maps, arrays,
                      linked lists, trees, queues, and stacks. Data structures
                      along with algorithms create the core of applications as
                      they manipulate and deliver the data to fulfill a specific
                      task.
                    </p>
                    <br />
                  </div>
                </GridItem>
              </GridContainer>
              <br />
              <hr />
              <GridContainer>
                <GridItem xs={2} sm={2} className={classes.marginLT}>
                  <img
                    src={uci}
                    alt="..."
                    className={classes.imgRounded + " " + classes.imgFluid}
                  />
                </GridItem>
                <GridItem xs={10} sm={9}>
                  <div className={classes.brand}>
                    <h1 className={classes.title}>
                      University of California, Irvine
                    </h1>
                  </div>
                  <div className={classes.brand}>
                    <h3 className={classes.subtitle}> Class of 2019 </h3>
                  </div>
                  <div className={classes.description}>
                    <h3 className={classes.subtitle}>
                      Business Economics, B.A.
                    </h3>
                    <br />
                    <p>
                      Using general economic theories, for both large and small
                      scale economies, to explain how production and consumerism
                      work together to create the greater economy. Taking a
                      business perspective to understand how the individual
                      business navigates through the ever-changing economy and
                      through use of Game Theory how a businesses an develop
                      strategies to thrive.
                    </p>
                    <p>
                      Courses: Machine Learning, Econometrics, Multivariate
                      Calculus, Linear Algebra, Statistics.
                    </p>
                    <br />
                    <h3 className={classes.subtitle}> Social Ecology, B.A. </h3>
                    <p>
                      Curriculum that combines the study of Psychology and
                      Social Behavior, Policy Planning and Design, and
                      Criminology to understand human beings and their
                      environments. Psychology enables us to understand the
                      human psyche and how its shaped. Urban planning, policies,
                      and criminal law affect our behavior by giving us a
                      boundaries, that can be physical or lawful, that we cannot
                      go beyond. Coursework also attempts to critique those
                      policies and laws and study the aftermath while evaluating
                      its effectiveness.
                    </p>
                    <p>
                      Courses: Policy Planning & Design, Psychology & Social
                      Behavior
                    </p>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer />
            </div>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
