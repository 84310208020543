import { container } from "assets/jss/material-kit-react.js";

const carouselStyle = {
  section: {
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
};

export default carouselStyle;
