import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import pom1 from "assets/img/pomodoro-shots/pom1.png";
import pom2 from "assets/img/pomodoro-shots/pom2.png";
import pom3 from "assets/img/pomodoro-shots/pom3.png";
import pom4 from "assets/img/pomodoro-shots/pom4.png";
import pom5 from "assets/img/pomodoro-shots/pom5.png";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={pom1} alt="first slide" className="slick-image" />
                  <div className="slick-caption">
                    <p>first</p>
                  </div>
                </div>
                <div>
                  <img src={pom2} alt="second slide" className="slick-image" />
                  <div className="slick-caption">
                    <p>second</p>
                  </div>
                </div>
                <div>
                  <img src={pom3} alt="third slide" className="slick-image" />
                  <div className="slick-caption">
                    <p>third</p>
                  </div>
                </div>
                <div>
                  <img src={pom4} alt="second slide" className="slick-image" />
                  <div className="slick-caption">
                    <p>fourth</p>
                  </div>
                </div>
                <div>
                  <img src={pom5} alt="second slide" className="slick-image" />
                  <div className="slick-caption">
                    <p>fifth</p>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
